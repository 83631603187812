.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
