.container {
  border-left: 1px solid var(--color-border);
}
.clearButton {
  position: absolute;
}

.withClear {
  padding-right: 48px;
}
