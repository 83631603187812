.selectControl {
  @extend .textField;
  width: 120px;
}

.wordFormContainer {
  padding: 8px;
  display: flex;
  flex-direction: row;
}

.rowContainer {
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.container {
  height: 200px;
}

.textField {
  margin: 4px;
}

.noteField {
  @extend .textField;
  padding: 8px;
  width: 270px;
}

.headerText {
  margin-left: 16px;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
}
