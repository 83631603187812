.audio {
  margin: 16px 0;
}

.quickEditForm {
  position: relative;
  width: 100%;
  height: 100%;
}

.quickEditInputField {
  margin: 0;
  height: 100%;

  & > div {
    height: 100%;
    padding: 0;
  }
}

.quickSaveButton {
  position: absolute;
  right: -10px;
  bottom: -10px;
}
