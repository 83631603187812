.root {
  height: 100%;
  padding-top: 20vh;
  max-width: 500px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.avatar {
  background-color: #3c00ba;
}
