.addButton {
  margin-left: 27px;
}

.searchField {
  margin-left: auto;
}

.media {
  border-radius: 10px;
  width: 100%;
  margin-top: 8px;
}

.userCount {
  font-weight: bold;
}

.presentationHeading {
  margin-top: 16px;
}

.id {
  margin-bottom: 16px;
}
