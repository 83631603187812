.optionsContainer {
  // height: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  > div {
    &:not(.option) {
      margin-top: auto;
    }
  }
}

.option {
  display: flex;
  flex-direction: row;
  width: 100%;
  :global {
    .MuiFormControl-root {
      margin-top: 5px;
      height: 46px;
    }
  }
}

.divider {
  margin: 20px 0;
}

.audioAnswerForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.audioAnswer {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 20px;
}

.imageOption {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 16px;
}

.translateButton {
  margin: 12px 0 24px 0;
}

.imageUpload {
  display: flex;
  position: relative;
  height: fit-content;
  width: fit-content;
  input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 368px;
    height: 207px;
    border-radius: 8px;
    font-size: 18px;
    border: 1px solid lightgrey;
    transition: 200ms all;
    margin: 0;
    background-color: white;

    &:hover {
      border: 1px solid black;
    }
  }

  span {
    position: absolute;
    text-align: center;
    color: red;
    font-size: 11px;
    bottom: 0;
    transform: translate(-50%, 105%);
    left: 50%;
    width: 100%;
  }
}

.imageDisplay {
  & > div {
    position: relative;
    display: flex;
  }

  img {
    width: 368px;
    height: 207px;
    object-fit: cover;
    margin: 0;
    border-radius: 16px;
  }

  &Overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 368px;
    height: 207px;
    border-radius: 16px;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    transition: 150ms all;

    svg {
      fill: white;
      width: 40px;
      height: 40px;
    }

    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
