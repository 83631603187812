.player {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  audio {
    flex: 3 0 auto;
    height: 39px;
    outline: none;
    width: 100%;
  }

  button {
    flex: 1 0 auto;
    white-space: nowrap;
    margin-top: 10px;
    width: 90%;
  }
}

.upload {
  position: relative;
  input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    &:disabled {
      opacity: 0.5;
    }
  }

  label {
    display: flex;
    z-index: 1;
    cursor: pointer;
    margin: 0;
    padding: 5px 15px;
    width: 100%;
    background-color: transparent;
    color: #3c00ba;
    border: 1px solid rgba(60, 0, 186, 0.5);
    font-size: 0.875rem;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      border: 1px solid #3c00ba;
      background-color: rgba(60, 0, 186, 0.04);
    }

    &[aria-disabled='true'] {
      opacity: 0.5;
      &:hover {
        cursor: default;
        border: 1px solid rgba(60, 0, 186, 0.5);
        background-color: transparent;
      }
    }
  }

  span {
    position: absolute;
    text-align: center;
    color: red;
    font-size: 11px;
    bottom: 0;
    transform: translate(-50%, 105%);
    left: 50%;
    width: 100%;
  }
}
