.divider {
  margin: 20px 0;
}

.row {
  margin-bottom: 16px;
}

.video {
  max-width: 100%;
}

.error {
  color: var(--color-error);
  font-size: 0.75rem;
}
