.option {
  border: 1px solid;
  border-radius: 4px;
  margin: 0 4px;
  padding: 4px;
}

.img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
}
