.NavButton {
  display: flex;
  height: 100%;
  text-decoration: none;
  align-items: center;
  padding: 0 20px;
  text-transform: uppercase;
  color: var(--color-text-secondary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: var(--color-primary);
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
  }

  &:hover {
    color: var(--color-text-primary);
  }
}

.NavButtonActive {
  color: var(--color-text-primary);
  &:after {
    opacity: 1;
  }
}
