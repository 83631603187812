.levelSourceText {
  margin-right: 4px;
  font-weight: bold;
}

.labelText {
  display: flex;
  flex-direction: row;
}

.otherWordSensesContainer {
  display: flex;
  flex-direction: column;
}

.otherWordContainer {
  justify-content: space-between;
  margin: 2px;
}
.popperTextContainer {
  display: flex;
  flex-direction: row;
}

.popperLeftContainer {
  width: 280px;
}

.popperContainer {
  padding: 8px;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.statsContainer {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-between;
  padding: 8px;
}

.cefrLevelsPercentagesContainer {
  display: flex;
  flex-direction: row;
  margin: 2px;
  align-items: center;
  p:nth-child(2) {
    margin-left: 4px;
  }
}

.selectControl {
  margin-left: 8px;
  width: 100px;
}

.tokenHeader {
  font-size: 16px;
  font-weight: 600;
  overflow-wrap: break-word;
}
