.book {
  display: flex;
  align-items: center;
  gap: 16px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
