.statsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
}

.cefrLevelsPercentagesContainer {
  display: flex;
  flex-direction: row;
  margin: 2px;
  align-items: center;
  p:nth-child(2) {
    margin-left: 4px;
  }
}

.cefrColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
