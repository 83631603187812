.segment {
  position: relative;

  &:hover {
    &:not(.disabled) {
      label {
        border-color: var(--color-border-hover);
      }
    }
  }

  &.disabled {
    label {
      cursor: default;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: 0;
    &:checked + label {
      border-color: var(--color-primary);
    }
  }

  label {
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 1;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: var(--color-text-secondary);

    background: #ffffff;
    border: 1px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 14px 18px;
  }
}
