.imageContainer {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
