html,
body {
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
}

#root {
  height: 100%;
  overflow: hidden;
}
