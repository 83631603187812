.selector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.select {
  width: 280px;
}
