.divider {
  margin: 20px 0;
}

.wordsContainer {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
}

.word {
  display: flex;
  flex-direction: row;
  width: 100%;
  :global {
    .MuiFormControl-root {
      margin-top: 5px;
    }
  }
}
