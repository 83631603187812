.saveSentenceBtn {
  margin-top: 18px;
}
.divider {
  margin: 20px 0;
}

.marginTop {
  margin-top: 16px;
}
