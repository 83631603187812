.grammarContainer {
  padding: 8px;
}

.grammarList {
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    margin-bottom: 24px;
  }
}

.item {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.tableWrapper {
  margin-left: 44px;
}

.patternTable {
  width: 100%;
  border-spacing: 0;

  tr:nth-child(even) {
    background-color: #f0f0f0;
  }
}

.level {
  color: #000;
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
}

.patternName {
  font-style: italic;
}

.overall {
  font-weight: bold;
}
