.root {
  max-width: 1240px;
  margin: 0 auto;
  :global {
    .MuiCard-root {
      height: 100%;
    }
    .MuiCardContent-root {
      padding: 16px 26px;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.componentHeading {
  font-size: 1.2rem;
  margin: 0;
  font-weight: bold;
}

.card:hover {
  .actions {
    visibility: visible;
  }
}

.actions {
  visibility: hidden;
  display: flex;
  justify-content: flex-end;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.addContent {
  display: flex;

  svg {
    font-size: inherit;
  }
}

.placeholder {
  border: 2px dashed var(--color-border);
}

.selector {
  margin-top: 16px;
}

.draggableContainer {
  display: flex;
  align-items: center;
}

.dropLocation {
  border: 2px dashed var(--color-border);
  border-radius: 4px;
}

.dragIndicator {
  color: var(--color-border);
  transition: width 0.2s;
  width: 30px;

  &.hidden {
    width: 0;
    overflow: hidden;
  }
}

.addButton {
  border: 1px solid var(--color-border);
  color: var(--color-text-secondary);
  background: #fff;
  width: 100%;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
}

.sectionSelector {
  width: 200px;
}

.sectionSelectItem {
  display: flex;
  gap: 4px;
  align-items: center;
}
