@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --font-family: 'Roboto', sans-serif;

  --color-primary: #3c00ba;
  --color-border: #efeff1;
  --color-border-hover: #333557;
  --color-error: #f44336;

  --color-grey-3: #f5f5f5;
  --color-grey-8: #595959;

  --color-text-primary: #333557;
  --color-text-secondary: #999bba;

  --dashboard-header-height: 74px;
}
