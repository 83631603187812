.container {
}

.section {
  margin-bottom: 20px;
}

.versionsContainer {
  display: flex;
  flex-direction: row;
  padding: 4px;
  border: 1px solid black;
  border-radius: 8px;
  min-height: 50px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.chip {
  margin: 4px;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
