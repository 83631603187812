.addButton {
  margin-left: 27px;
}

.searchField {
  margin-left: auto;
}

.tableRow:hover {
  cursor: pointer;
}
