@import '~@astrid/components/src/scss/utils';

.root {
  padding: 0 40px;
  border: 2px solid rgba(190, 194, 199, 0.2);
  height: var(--dashboard-header-height);
  background: #ffffff;
}

.container {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav {
  display: flex;
  flex-direction: row;
  width: calc(100% - 175px);
  height: 100%;
  & > div {
    display: flex;
    flex-direction: row;
  }
}

.navMain {
  margin: 0 0 0 50px;
}

.navRight {
  margin-left: auto;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #001593;
  user-select: none;
  line-height: 13px;

  svg {
    width: 110px;
    height: 100%;
    margin-right: 6px;
  }
}
