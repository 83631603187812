.conversation {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.instructions {
  white-space: pre-wrap;
  margin-bottom: 16px;

  h4 {
    font-size: 1rem;
  }
}

.video {
  max-height: none;
  width: 124px;
}
