.option {
  display: flex;
  align-items: center;
  gap: 16px;

  .avatar {
    border-radius: 4px;
  }
}

.book {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .title {
    font-size: 1rem;
    font-weight: bold;
  }

  .bookInfo {
    min-width: 200px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
